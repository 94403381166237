export default function config() {
  // Table Handlers
  const tableColumns = [
    { key: 'event', label: 'Event' },
    { key: 'created_time', label: 'Date', thStyle: { width: '250px' } },
    { key: 'created_hour', label: 'Time' },
    { key: 'auditable_type', label: 'Type' },
    { key: 'user.username', label: 'User' },
  ]
  return {
    tableColumns,
  }
}
