<template>
  <div class="pb-2">
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
    >
      <template #cell(created_at)="{ data }">
        {{ convertTZ(data.value) }}
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import config from '../auditConfig'

export default {
  name: 'AuditTable',
  components: {
    LTable,
  },
  methods: {
    getValue(data, tableColumnName) {
      return data.item[tableColumnName] ?? {}
    },
  },
  setup() {
    const MODULE_NAME = 'audits'
    const { tableColumns } = config()
    return {
      tableColumns,
      MODULE_NAME,
    }
  },
}
</script>
